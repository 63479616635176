import React from 'react';
import styled from 'styled-components';
import { device } from './device';

const Background = styled.section`
  background: #2a2b4d; 
  padding: 20px 0;
  width: 100%
  height: 100%;

  @media screen and ${device.mobileL} {
    min-height: 10vh;
    margin: 0px auto;
    
  }
`
const FooterGroup = styled.div`
  display: inline;
  padding-bottom: 0px;

  @media screen and ${device.mobileL} {
    padding-top: 0px;
   
  }

`

const Copyright = styled.p`
  color: #aab0ba;
  font-size: 16px;
  margin: 0;
  float: right;
  padding: 15px 30px;


  @media screen and ${device.mobileL} {
    font-size: 12px;
    margin-top: -40px;
    float:none;
    margin-left: -15px; 
  }
`

const rpad = {"padding-top": "3vh"}


const Footer = props => (
  <Background>
    <FooterGroup>
      <img className="footer-logo" src="/images/dot-logo-r.png"  alt="dot-logo" style={rpad}/>
      <Copyright>© 2019 Department of Tourism. All rights reserved.</Copyright>
    </FooterGroup>
  </Background>
)

export default Footer;
