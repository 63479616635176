import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import '../style/animate.css';
import { device } from './device'
import Moment from 'react-moment';
import 'moment-timezone';
import ReactLoading from 'react-loading';


const Background = styled.section`
  background: url('/images/bg-fold-12.png') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  display: grid;
  align-items: center;
  height: 100%
  min-height: 100vh;
  width: 100%
  @media screen and ${device.mobileL} {
    width: 100%
  }

`

const Wrapper = styled.div`
  margin: 0 auto;
  text-align: center;
`

const Number = styled.h1`
  color: white;
  font-family: 'Barabara';
  font-size: 120px;
  margin: 0;
  height: 175px;

  @media screen and ${device.mobileL} {
    font-size: 60px;  
    height: 100px;
  }
`

const Copy = styled.p`
  color: white;
  font-family: 'Barabara';
  font-size: 30px;
  text-transform: uppercase;
  margin: -5px 0 0;


  @media screen and ${device.mobileL} {
    font-size: 16px;  
    line-height: 1.8;
    width: 100%;
  }
`

const Spinner = styled.h3`
  color: white;
  font-family: 'Asap';
  font-size: 30px;
  text-transform: uppercase;

  @media screen and ${device.mobileL} {
    font-size: 23px;  
    line-height: 1.8;
    width: 100%;
  }

`

const Timer = styled.p`
  color: white;
  font-size: 14px;
  margin: 0;
`

const Loader = styled.p `
position: relative;
left: 45%;
margin-top:-10px;

`

const dateToFormat = Date.now()

const Counter = props => (

  <Background>
    <Wrapper>
      <Loader>
          <ReactLoading type={props.loader} color={"white"} height={props.loadheight} width={props.loadwidth} />
      </Loader>
      <Spinner className="animated flash">{props.user}</Spinner>
      <Copy>
        Thank you for sharing the fun 
      </Copy>
      <Loader>
      <ReactLoading type={props.loader} color={"white"} height={props.loadheight} width={props.loadwidth}  />
      </Loader>
      <Number>
        <NumberFormat value={props.counter} displayType={'text'} thousandSeparator={true} className="animated flash" />
      </Number>
      <Copy className="-lesser">#itsmorefuninthephilippines</Copy>
      <Timer>As of <Moment format="MMMM DD, YYYY hh:mm:ss">{dateToFormat}</Moment> </Timer>
      <Timer>
      from Instagram.
      </Timer>
    </Wrapper>
  </Background>
)



export default Counter;
