import React from 'react';
import styled from 'styled-components';
import { device } from './device'

const Background = styled.div`
  background: rgba(255, 255, 255, 0.95); 
  padding: 20px 0;
  width: 100%
  
  @media screen and ${device.mobileL} {
    min-height: 10vh;
    padding: 5px;
    margin: 0px auto;
    
  }
`
const HeaderGroup = styled.div`
@media screen and ${device.mobileL} {
display: inline-flex;
justify-content: space-evenly;
}
`
/*
const Copyright = styled.p`
  color: #aab0ba;
  font-size: 16px;
  margin: 0;
`
*/


const Left = styled.div`
  display: inline;
  
  
`

const Right = styled.div`
  display: inline;
  float: right;
  padding: 12px 30px;

  @media screen and ${device.mobileL} {
    display: flex;
    padding: 15px 0px 0px 0px;
    float: none;

    }


`

const NavLink = styled.a`
  color: #1d519c;
  font-size: 18px;
  padding-left: 40px;
  text-transform: uppercase;
  transition: 0.4s;

  &:hover {
    transform: translateY(-10px);
  }

  @media screen and ${device.mobileL} {
   display: inline-flex;
   font-size: 13px;
   margin-left: -10px;
   margin-top: 5px;
   justify-content: space-evenly;
   &:hover {
    transform: translateY(-10px);
  }


  
  }
`

const rpad = {"padding-top": "3vh"}

const Header = props => (
  <Background>
    <HeaderGroup>
      <Left>
        <img className="header-logo" src="/images/dot-logo-r.png" alt="dot-logo" style={rpad} />
      </Left>
      <Right>
        <NavLink><a href="#section-fun" className="-blue -link" >Be part of the fun</a></NavLink>
        <NavLink><a href="#section-download" className="-blue -link">Fun stuff</a></NavLink>
      </Right>

    </HeaderGroup>
  </Background>
)

export default Header;
/*
// burger0
 <a href="javascript:void(0);" className="icon" onclick="myFunction()">
          <i class="fa fa-bars"></i>
        </a>
      
*/
