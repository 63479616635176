import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//import { BrowserRouter as Router, Route, BrowserRouter } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
//import Fold from './components/Fold';


ReactDOM.render((
 <BrowserRouter>
 <App />
 </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
