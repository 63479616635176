import React from 'react';
import styled from 'styled-components';
import { device } from './device';

const Background = styled.section`
  background-color: #f2f2f2;
  background-size: cover;
  display: grid;
  align-items: center;
  height: 100%;
  min-height: 80vh;
  margin: 0;
  width: 100%;
`
//background: url('/images/bg-section-2.png') no-repeat center center;

const Wrapper = styled.div`
  max-width: 800px;
  padding: 20px;
  margin: 0 auto;
`

const Title = styled.h1`
  color: #1d519c;
  font-family: 'Barabara';
  font-size: 34px;
  letter-spacing: 1.4px;
  text-transform: uppercase;

  @media screen and ${device.mobileL} {
    font-size: 30px;
  }
`

const Body = styled.p`
  font-family: 'Asap', sans-serif;
  font-size: 20px;
  line-height: 1.8;
`

const FullSection = props => (
  <Background>
    <Wrapper>
      <Title>{props.title}</Title>
      <Body>
        Are we all ready?! Everyone can be part of  
        <span> <a className="-red" href="https://www.instagram.com/explore/tags/itsmorefuninthephilippines/" target="_blank" rel="noopener noreferrer" >#itsmorefuninthephilippines</a></span>
      </Body>
      <Body>All the amazing photos, even the words used for the new Philippine tourism campaign were sourced from real travelers’ posts - with permissions of course, and the DOT’s deepest thanks. So jump in too!</Body>
      <Body>
        We can’t wait to see YOUR posts in the 
        <span> <a className="-red" href="https://www.instagram.com/explore/tags/itsmorefuninthephilippines/" target="_blank" rel="noopener noreferrer" ># itsmorefuninthephilippines</a> </span> 
         stream.
      </Body>
    </Wrapper>
  </Background>
)

export default FullSection;
