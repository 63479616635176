import React from 'react';
import styled from 'styled-components';
import { device } from './device';

const Background = styled.section`
  background: url('/images/bg-section-4.png') no-repeat center center;
  background-size: cover;
  display: grid;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  height: 100%;
  margin: 0;
  width: 100%
`

const Container = styled.div`
`

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 20px;
`

const Title = styled.h1`
  font-family: 'Barabara';
  font-size: 36px;
  text-transform: uppercase;
  margin: 0;

  @media screen and ${device.mobileL} {
    font-size: 30px;
  }
`

const Body = styled.p`
  color: white;
  font-size: 20px;
  line-height: 1.8;
  margin: 0;

  @media screen and ${device.mobileL} {
    font-size: 16px;
  }
`


const HalfSection = props => (
  <Background id="section-fun">
    <Container>
      <Wrapper>
        <Title>{props.title}</Title>
        <Body>
          <li>Share our proudly Philippine-made content</li>
          <li>
            Post your own 
            <span> <a className="-blue" href="https://www.instagram.com/explore/tags/itsmorefuninthephilippines/" target="_blank" rel="noopener noreferrer">#itsmorefuninthephilippines</a> </span>
            photos on Instagram
          </li>
          <li>
            Tag your old Philippine travel photos with <br/>
            <span> <a className="-blue" href="https://www.instagram.com/explore/tags/itsmorefuninthephilippines/" target="_blank" rel="noopener noreferrer">#itsmorefuninthephilippines</a></span>
          </li>
          <li>
            Follow us!<br/>
            <a className="social-logo" href="https://www.facebook.com/itsmorefuninthePhilippines/" target="_blank" rel="noopener noreferrer" ><img src="/images/social-facebook.png" alt="fb" /></a>
            <a className="social-logo" href="https://twitter.com/TourismPHL" target="_blank" rel="noopener noreferrer" ><img src="/images/social-twitter.png" alt="twitter" /></a>
            <a className="social-logo" href="https://www.instagram.com/tourism_phl/" target="_blank" rel="noopener noreferrer"><img src="/images/social-ig.png" alt="ig" /></a>
          </li>
          
        </Body>
      </Wrapper>
    </Container>
  </Background>
)

export default HalfSection;
