import React from 'react';
import styled from 'styled-components';
import carousell1 from '../carousellimg/carousell-1.png'
import carousell2 from '../carousellimg/carousell-2.png'
import ScrollContainer from 'react-indiana-drag-scroll'


const Background = styled.section`
  background-color: #f2f2f2;
  background-size: cover;
  display: grid;
  align-items: center;
  height: 100%;
  min-height: 80vh;
  margin: 0;
  width: 100%;

`

const FullSection = props => (
  <Background>
    <ScrollContainer className= "scrollable" >
      <img src={carousell1} alt="1" />
      <img src={carousell2} alt="1" />
      </ScrollContainer>
  </Background>
)

export default FullSection;
