import React, { Component, Fragment } from 'react';
//import logo from '../logo.svg';
import './style/App.css';
import './style/animate.css';
import FullSection from './components/FullSection.jsx';
import Carousell from './components/Carousell.jsx';
import HalfSection from './components/HalfSection.jsx';
import Counter from './components/Counter.jsx';
import Toolkit from './components/Toolkit.jsx';
import Header from './components/Header.jsx';
import Footer from './components/Footer.jsx';


class App extends Component {
  constructor (props) {
    super(props)
    this.state = { users:"", iter: 0, loader: "bubbles", loadheight: 50, loadwidth: 50, loadheightb: 50, loadwidthb: 50}
  }
 
async componentWillMount() {
// fires immediately before the initial render
  //const res = await fetch('https://cors-anywhere-opjyaegzad.now.sh/https://dot-scrapper.herokuapp.com/getTotalPosts?n=https://www.instagram.com/explore/tags/itsmorefuninthephilippines/')
  const res = await fetch('https://dot-scrapper.herokuapp.com/getTotalHashtagCount')
  const numba = await res.json();

  console.log("Fetched @ Mount: ", numba)
  //change to numba
  //let count = parseInt(numba.total) + 200632;
    let count = parseInt(numba.total);
 
  this.setState({
    counter : count,
    loader: "blank",
    loadheight: 0, 
    loadwidth: 0
  })

  await fetch('https://api.sheety.co/24bf98d4-b5ef-4527-bd77-dd466a66ce0d')
    .then(response => response.json())
    .then(json => {
    this.setState({
      users : json[0].ig,
      iter: this.state.iter + 1,
      loadheightb: 0, 
      loadwidthb: 0
    })
    
  })
}
  
async componentDidMount() {  

  try {
    setInterval(async () => {
      const res = await fetch('https://dot-scrapper.herokuapp.com/getTotalHashtagCount')
    //const res = await fetch('https://cors-anywhere-opjyaegzad.now.sh/https://dot-scrapper.herokuapp.com/getTotalPosts?n=https://www.instagram.com/explore/tags/itsmorefuninthephilippines/')
      const numba = await res.json();
    
    console.log("Fetched @ Update: ", numba)
    //let count = parseInt(numba.total) + 200632; // twitter from 2/2/16 - 2/2/19
    let count = parseInt(numba.total);
    this.setState({
      counter : count,
    })
    
    console.log(count)
    console.log(this.state.iter)
    }, 15000);

 
    setInterval(async() => {
      await fetch('https://api.sheety.co/24bf98d4-b5ef-4527-bd77-dd466a66ce0d')
    .then(response => response.json())
    .then(json => {
      if (this.state.iter === 58){
      this.setState({
        users : json[0].ig,
        iter: 0,
        loadheight: 0, 
        loadwidth: 0
      })} else {
        this.setState({
          users : json[this.state.iter].ig,
          iter: this.state.iter + 1,
          loadheight: 0, 
          loadwidth: 0
        })
      }
  })
    console.log("Iteration count:", this.state.iter)
    }, 900000)

  
  } catch (e){
    console.log(e);
  }

  }
  
  render() {
    return (
      
      <Fragment>
        <Header />
        <Counter counter={this.state.counter} user= {this.state.users}  loader={this.state.loader} loadheight = {this.state.loadheight} loadwidth={this.state.loadwidth} loadheightb= {this.state.loadheightb} loadwidthb={this.state.loadwidthb} />
        <FullSection title="We're about to have even more fun!" /> 
        <Carousell />
        <HalfSection id="section-fun" title="Be part of the fun too!" /> 
        <Toolkit id="section-download" title="Download the fun stuff"/> 
        <Footer />
      </Fragment>
      
    );
  }
}

export default App;
