import React from 'react';
import styled from 'styled-components';
import { device } from './device';

const Background = styled.section`
  background: url('/images/bg-section-5.png') no-repeat center center;
  background-attachment: fixed;
  background-size: cover;
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 40vh;
  margin: 0;
  width: 100%;
`

const Wrapper = styled.div`
  max-width: 800px;
  padding: 20px;
`

const Title = styled.h1`
  color: #abc637;
  font-family: 'Barabara';
  font-size: 34px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin: 0;

  @media screen and ${device.mobileL} {
    font-size: 30px;
  }
`

const Body = styled.p`
  font-size: 20px;
  line-height: 1.8;
  margin: 0;

  @media screen and ${device.mobileL} {
    font-size: 16px;
  }

`

const Toolkit = props => (
  <Background id="section-download">
    <Wrapper>
      <Title>{props.title}</Title>
      <Body><a className="download" href="http://bit.ly/dot_fonts" >Barabara Font</a></Body>
      <Body><a className="download" href="http://bit.ly/dot-logos" >Official Logos</a></Body>
      <Body><a className="download" href="/images/Location-Pin-Icon.png" >Location Pin Icon</a></Body>
    </Wrapper>
  </Background>
)


export default Toolkit;
